import React, { useEffect, useLayoutEffect, useState } from "react";
import "../assets/scss/cinestar.scss";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ContactPerson } from "../components/contact-person";
import SliderHorizontal from "../components/Slider-horizontal";
import ConfettiReal from "../components/ConfettiReal";
gsap.registerPlugin(ScrollTrigger);

function CinestarScreen() {
  const [fire, setFire] = useState<boolean>(false);

  useLayoutEffect(() => {
    document.title = "Wir wollen euch Cinestar!";
    setTimeout(() => {
      setFire(true);
    }, 500);
  }, [fire]);

  return (
    <>
      <header
        className={
          "relative z-50 text-center  lg:flex container lg:justify-end lg:gap-12 mt-12"
        }
      >
        <div className={"button-inline"}>
          Fragen? Ruf Alex an <a href={`tel: 015157743253`}>0151 577 43 253</a>
        </div>
        <a
          href={"https://popcorn-solution.com"}
          target={"_blank"}
          rel="noreferrer"
          className={"button"}
        >
          Zur Popcorn-Crew
        </a>
      </header>
      <main className={"my-12 lg:my-24"}>
        <div className={"z-50 relative"}>{fire && <ConfettiReal />}</div>
        <div className={"space-y-24 lg:space-y-48"}>
          <HeroSection />
          <TeamSection />
          <WeWantYouSection />
          <LogoSection />
          <ContactSection />
        </div>
      </main>
      <footer className={"mb-6 text-white"}>
        <div className={"container space-y-4"}>
          <div
            className={"flex flex-col lg:flex-row justify-between lg:gap-12"}
          >
            <div>
              <p className={""}>
                Dieser Film wurde produziert von der{" "}
                <a
                  href={"https://popcorn-solution.com"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Popcorn-Crew
                </a>
              </p>
            </div>
            <div className={"space-x-4"}>
              <a
                href={"https://popcorn-solution.com/impressum"}
                target={"_blank"}
                rel="noreferrer"
              >
                Impressum
              </a>
              <a
                href={"https://popcorn-solution.com/datenschutz"}
                target={"_blank"}
                rel="noreferrer"
              >
                Datenschutz
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

function HeroSection() {
  useLayoutEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".cinema_screen",
        start: "top +=100px",
        // end: "+=300px",
        end: "bottom bottom",
        markers: false,
        pin: true,
        scrub: 1,
        pinSpacing: false,
        id: "cinema",
      },
    });

    // Need to unmount these
    return () => {
      ScrollTrigger.refresh(); // New Line
      tl1.kill();
    };
  }, []);

  const headlines: Array<string> = [
    "Das passt wie die Faust aufs Auge - Cinestar & Popcorn",
  ];

  return (
    <div className={"hero relative"}>
      <div className={"relative z-10"}>
        <div className={"hero_inner"}>
          <div className={"container space-y-6 lg:space-y-12"}>
            <h1 className={"relative z-10"}>
              {headlines[Math.floor(Math.random() * headlines.length)]}
            </h1>
            <p className={"relative z-10"}>
              Bühne frei und Vorhang auf für das ganz große Kino der Emotionen.
              Netflix & Chill bockt, aber wer an Valentinstag die Herzen im
              Sturm erobern möchte, braucht ne fette Leinwand! Und für’s easy
              peasy Ticket buchen, haben wir unsere verdammt heiße
              Popcorn-Maschine angeworfen und euch ein geiles neues
              Website-Konzept hingeballert. Garantiert ohne Eisberg aber mit
              viel Tiefgang. Cinestar im Look von Popcorn Solution, eurer neuen
              Marketingagentur - excuse me: wir haben schließlich 2023.
            </p>
          </div>
        </div>
      </div>
      <div className={"mt-12 lg:-mt-64 cinema"}>
        <div className={"container cinema_screen"}>
          <img
            src={require("../assets/images/cinestar/macbook.png")}
            alt="xd cinestar"
            className={"w-full h-auto lg:pb-[60%]"}
          />
        </div>
      </div>
      <div className={"mt-12 lg:mt-24 text-center"}>
        <a
          href={
            "https://xd.adobe.com/view/bf6170af-cb04-4940-877c-5e9ac31eabb2-ea32/?fullscreen"
          }
          target={"_blank"}
          rel="noreferrer"
          className={"button !p-8 rounded relative z-10"}
        >
          So könnte Cinestar 2023 aussehen!
        </a>
      </div>
      <img
        src={require("../assets/images/cinestar/screen.png")}
        alt={"Cinestar"}
      />
    </div>
  );
}

function WeWantYouSection() {
  return (
    <section className={"container space-y-6 lg:space-y-12"}>
      <h2>We want you!</h2>
      <p>
        Wir von Popcorn lieben Never Ending Stories und feiern jeden Film als
        wäre es der finale Showdown. Unsere Camera Roll ist bereits mit coolen
        namenhaften Kunden bestückt, aber ein atemberaubender Mix aus Drama,
        Comedy oder epischen Landschaften im Großformat fehlt uns noch. Wir sind
        bereits Cinestar Kinofans! Seid ihr Popcorn Fans? Dann schnappt euch
        jetzt euer Ticket für die heißeste Marketingagentur aus ganz Sachsen.
      </p>
    </section>
  );
}

function TeamSection() {
  const [result, setResult] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const call = await fetch(
        "https://cms.popcorn-solution.com/api/members?populate=avatar"
      );
      const res = await call.json();
      setResult(res.data);
    };
    fetchUsers();
  }, []);

  const sortOrder = [1, 4, 9, 10, 11, 6, 8, 7, 5, 4, 3, 2];

  return (
    <section
      className={"space-y-6 lg:space-y-12 relative z-10 overflow-hidden"}
    >
      <div className={"container"}>
        <h2 className={""}>Der kreative Haufen hinter Popcorn Solution</h2>
      </div>
      <div className="because_we h-auto">
        <SliderHorizontal>
          {result
            .sort(function (a: any, b: any) {
              return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
            })
            .map((person: any) => (
              <div className={"slick-slide"} key={person?.id}>
                <div className={"mx-12 mt-9 mb-6"}>
                  <p className={"text-base italic"}>
                    {person.attributes?.position}
                  </p>
                </div>
                <div className={"aspect-square"}>
                  <img
                    src={
                      person?.attributes?.avatar?.data?.attributes.formats.large
                        .url
                    }
                    alt={person.attributes.name}
                    className={"object-cover"}
                  />
                </div>
                <div className={"m-12"}>
                  <h3>{person.attributes.name}</h3>
                  <div className={"flex gap-2"}>
                    <i className="ri-star-fill"></i>
                    <i className="ri-star-fill"></i>
                    <i className="ri-star-fill"></i>
                    <i className="ri-star-fill"></i>
                    <i className="ri-star-fill"></i>
                  </div>
                </div>
              </div>
            ))}
        </SliderHorizontal>
      </div>
    </section>
  );
}
function LogoSection() {
  const logos = [
    "barthoptik.svg",
    "bodyshake.svg",
    "chemnitz99.svg",
    "fairment.svg",
    "saturn.svg",
    "vodafone.svg",
    "volksbank.svg",
    "wuerth.svg",
  ];
  return (
    <section className={"container"}>
      <div
        className={
          "divide-y overflow-hidden bg-white bg-opacity-10 grid grid-cols-2 lg:grid-cols-4 gap-px divide-y-0"
        }
      >
        {logos
          .sort(function () {
            return Math.random() - 0.5;
          })
          .map((e: string, i) => {
            return (
              <div key={i} className={"bg-main-dark p-12 lg:p-24 text-center"}>
                <img src={`../assets/images/logos/${e}`} alt={"logo"} />
              </div>
            );
          })}
      </div>
    </section>
  );
}

function ContactSection() {
  return (
    <section className={"container"}>
      <ContactPerson id={1} />
    </section>
  );
}

export default CinestarScreen;
