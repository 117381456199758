import React from "react";
import { Route, Routes } from "react-router-dom";
import OfferScreen from "./OfferScreen";
import CinestarScreen from "./CinestarScreen";
import NotFound from "./NotFound";
import TestScreen from "./TestScreen";

export const AppRouter = () => {
  const subdomain = window.location.host.split(".")[0];

  let element;
  switch (subdomain) {
    case "cinestar":
      element = <CinestarScreen />;
      break;
    default:
      element = <NotFound />;
  }

  return (
    <Routes>
      {subdomain === "angebot" && <Route path="/" element={<OfferScreen />} />}
      <Route path="/" element={element} />
      <Route path="/test" element={<TestScreen />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
