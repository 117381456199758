import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const token = "?token=TOKEN";
const baseURL = `https://my.sevdesk.de/api/v1/Order`;

interface Order {
  id: number;
  header: any;
}
interface OrderPosition {
  name: string;
  text: string;
}
function OfferScreen() {
  const [order, setOrder] = useState<Order>();
  const [positions, setPositions] = useState<OrderPosition[]>();

  const headlines: Array<string> = [
    "Stell dir vor, du hast was einmaliges",
    "Ich glaube, das ist dein Angebot",
  ];

  useLayoutEffect(() => {
    const c1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".App",
        scrub: 1,
        markers: true,
        start: "top +=100",
        end: "+=400",
      },
    });

    c1.from(".App-logo2", { rotateX: 0, y: 0 });
    c1.to(".App-logo2", {
      y: 40,
      rotateX: 0,
    });
    // Need to unmount these
    return () => {
      ScrollTrigger.refresh(); // New Line
      c1.kill();
    };
  }, []);

  useEffect(() => {
    axios.get(`${baseURL}/10605236/${token}`).then((response) => {
      const orderData: Order = response.data.objects[0];
      setOrder(orderData);
      axios
        .get(`${baseURL}/${orderData.id}/getPositions${token}`)
        .then((response) => {
          setPositions(response.data.objects);
        });
    });
  }, []);
  // if (!order) return <main>Error..</main>;

  return (
    <main>
      <div className="App m-24 space-y-24">
        <h1 className={"bg-white p-4 text-main-dark inline"}>
          {headlines[Math.floor(Math.random() * headlines.length)]} -{" "}
          {order?.header}
        </h1>
        <img
          src={require("../assets/images/monitor.png")}
          className="App-logo2 relative"
          alt="logo"
        />
        <div className={"space-y-12"}>
          {positions?.map((position: OrderPosition) => {
            return (
              <div key={position.name} className="flex flex-col space-y-6">
                <h2>{position.name}</h2>
                <div>
                  {position.text
                    .split("\n")
                    .map(function (item: string, idx: number) {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}

export default OfferScreen;
