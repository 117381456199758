import React from "react";

function NotFound() {
  return (
    <main>
      <div className="App m-24 space-y-24">Not Found..</div>
    </main>
  );
}

export default NotFound;
