import React, { useEffect, useState } from "react";

export function ContactPerson({ id }: { id: number }) {
  const [user, setUser] = useState<any>([]);

  useEffect(() => {
    const fetchUser = async () => {
      const call = await fetch(
        `https://cms.popcorn-solution.com/api/members/${id}?populate=avatar`
      );
      const res = await call.json();
      setUser(res.data);
    };
    fetchUser();
  }, [user]);

  return (
    <div>
      <div className="lg:flex lg:items-center lg:space-x-12 lg:justify-between space-y-12 lg:space-y-0">
        <div className="lg:w-6/12">
          <img
            src={user?.attributes?.avatar?.data?.attributes.formats.large.url}
            alt={user?.name}
            className={"w-full"}
          />
        </div>
        <div>
          <h1 className="main-y-space">Der Kopf dahinter</h1>
          <div>
            <h2>{user?.attributes?.name}</h2>
            <div className="mt-2">{user?.attributes?.position}</div>
          </div>
          <div className="lg:mt-12 space-y-6">
            <div>
              <p>
                Call me <span className="line-through">maybe</span>:{" "}
                <a href={`tel: ${user?.attributes?.phone}`}>
                  {user?.attributes?.phone}
                </a>
              </p>
              <a href={`mailto: ${user?.attributes?.mailto}`}>
                {user?.attributes?.mailto}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
